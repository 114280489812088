/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

 (function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages


        $('.flexslider').flexslider({
         animation: "slide",
         slideshow:false

         
       });


// begin affix
$(window).ready(function() {
  var viewport = $(window).width();

  if ( viewport <= 991 ) {
    console.log( "Mobile,", viewport ) ;}
    else {
      console.log( "Desktop,", viewport ) 
      var toggleAffix = function(affixElement, scrollElement, wrapper) {

        var height = affixElement.outerHeight(),
        top = wrapper.offset().top;

        if (scrollElement.scrollTop() >= top){
          wrapper.height(height);
          affixElement.addClass("affix");
        }
        else {
          affixElement.removeClass("affix");
          wrapper.height('auto');
        }

      };


      $('[data-toggle="affix"]').each(function() {
        var ele = $(this),
        wrapper = $('<div></div>');

        ele.before(wrapper);
        $(window).on('scroll resize', function() {
          toggleAffix(ele, $(this), wrapper);
        });

    // init
    toggleAffix(ele, $(window), wrapper);
  });
    };
  });





// end affix

$('body').scrollspy({ target: '#navbar-example' });

},
finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired




      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page

        /*begin instagram feed*/
        var token = '8650979507.1677ed0.f499521365914308b86911ab3ece6051',
        num_photos = 10;
        $.ajax({
          url: 'https://api.instagram.com/v1/users/self/media/recent',
          dataType: 'jsonp',
          type: 'GET',
          data: {access_token: token, count: num_photos},
          success: function(data){
            console.log(data);
            for( x in data.data ){
              $('#rudr_instafeed').append('<li class="img-thumbnail"><a href="'+data.data[x].link+'"><img class="img-fluid" src="'+data.data[x].images.low_resolution.url+'"></a></li>');

              // data.data[x].link - Instagram post URL a
            }
          },
          error: function(data){
            console.log(data);
          }
        });
        /*end instagram feed*/

      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.



    'gallery': {
      init: function() {


        $(document).on('click', '[data-toggle="lightbox"]', function(event) {
          event.preventDefault();
          $(this).ekkoLightbox();
        });

      }
    },  
    'lesson_packages': {
      init: function() {


        $(document).on('click', '[data-toggle="lightbox"]', function(event) {
          event.preventDefault();
          $(this).ekkoLightbox();
        });



      },
      finalize: function() {





// prevent the total being less than zero





        // JavaScript to be fired on the home page, after the init JS
        /*filter data picker*/
        // $('.date_text input').datetimepicker({
        //   controlType: 'select',
        //   showMinute: false,
        //   showButtonPanel: false,
        //   timeText: 'CHOOSE TIME:',
        //   oneLine: true,
        //   timeFormat: 'hh:mm tt',
        //   hourMin: 9,
        //   hourMax: 18,
        // });   


// var startDateTextBoxDate1 = $('#field_2_13 input');
// var endDateTextBoxDate2 = $('#field_2_17 input');

// $.timepicker.datetimeRange(
//   startDateTextBoxDate1,
//   endDateTextBoxDate2,
//   {
//     minInterval: (1000*180*60), // 3hr
//     timeFormat: 'hh:mm tt',
//     hourMin: 9,
//     hourMax: 18,
//     stepHour: 3,
//     showMinute: false,
//     timeText: 'CHOOSE TIME:',
//     oneLine: true,
//     start: {}, // start picker options
//     end: {} // end picker options         
//   }

//   );

//   //3nd slots
//   var startDateTextBoxDate4 = $('#field_2_17 input');
//   var endDateTextBoxDate3 = $('#field_2_18 input');

//   $.timepicker.datetimeRange(
//     startDateTextBoxDate4,
//     endDateTextBoxDate3,
//     {
//     minInterval: (1000*180*60), // 3hr
//     timeFormat: 'hh:mm tt',
//     hourMin: 9,
//     hourMax: 18,
//     stepHour: 3,
//     showMinute: false,
//     timeText: 'CHOOSE TIME:',
//     oneLine: true,
//     start: {}, // start picker options
//     end: {} // end picker options         
//   }
//   );  

//   //4nd slots
//   var startDateTextBoxDate5 = $('#field_2_18 input');
//   var endDateTextBoxDate4 = $('#field_2_19 input');

//   $.timepicker.datetimeRange(
//     startDateTextBoxDate5,
//     endDateTextBoxDate4,
//     {
//    minInterval: (1000*180*60), // 3hr
//     timeFormat: 'hh:mm tt',
//     hourMin: 9,
//     hourMax: 18,
//     stepHour: 3,
//     showMinute: false,
//     timeText: 'CHOOSE TIME:',
//     oneLine: true,
//     start: {}, // start picker options
//     end: {} // end picker options         
//   }
//   );  

//   //5nd slots
//   var startDateTextBoxDate6 = $('#field_2_13 input');
//   var endDateTextBoxDate5 = $('#field_2_20 input');

//   $.timepicker.datetimeRange(
//     startDateTextBoxDate6,
//     endDateTextBoxDate5,
//     {
//     //minInterval: (1000*180*60), // 3hr
//     minInterval: (1000*60*60*24*1), // 1 day
//     timeFormat: 'hh:mm tt',
//     hourMin: 9,
//     hourMax: 18,
//     stepHour: 3,
//     showMinute: false,
//     timeText: 'CHOOSE TIME:',
//     oneLine: true,
//     start: {}, // start picker options
//     end: {} // end picker options         
//   }
//   );


}
},


'cruises' :{
  init: function() {

  },
  finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
        /*filter data picker*/
        // $('#input_5_13').datetimepicker({
        //   controlType: 'select',
        //   showMinute: false,
        //   showButtonPanel: true,
        //   timeText: 'CHOOSE TIME:',
        //   oneLine: true,
        //   timeFormat: 'hh:mm tt',
        //   stepHour: 3,
        //   hourMin: 9,
        //   hourMax: 18
        // });

}

},

'about_us': {
  init: function() {
        // JavaScript to be fired on the about us page
      }
    },
    'single_expedition' : {
     init: function() {



      $(".video").click(function () {
        var theModal = $(this).data("target"),
        videoSRC = $(this).attr("data-video"),
        videoSRCauto = videoSRC + "?modestbranding=1&rel=0&controls=0&showinfo=0&html5=1&autoplay=1";
        $(theModal + ' iframe').attr('src', videoSRCauto);
        $(theModal).on('click', function(){
          $(theModal + ' iframe').attr('src', videoSRC);
        });
      });





    }
  }
};

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);
  $('#myModal').on('shown.bs.modal', function (e) {
    $('input.search-field', this).trigger('focus');
  });

})(jQuery); // Fully reference jQuery after this point.
